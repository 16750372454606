@import "../variables.scss";

.bi-container {
  width: fit-content;
}

.bi-document-row {
  width: 100%;
  word-break: break-word;
}

.bi-document-button {
  width: 50%;
}

.clients-email {
  p {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  p:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
}
.header-validation-popup {
  font-weight: bold;
}

.hidden-doc-line {
  p {
    color: $grey-text;
  }
}
