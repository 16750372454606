
.back-button{
    padding: 20px !important;
    position: absolute !important;
    left: 0;
}
.no-hover:hover {
    background-color: white !important;
    border: 0px;
    box-shadow: none !important;
}
.custom-back-button {
    button {
            padding: 0;
    
        }
    position: absolute !important;
    left: 20px;
}
.hide-back-button {
    visibility: hidden;
}