.container {

    width: 80%;
    margin: auto;
    //margin-top: 20%;
    text-align: center;
    //border: 1px solid blue;

    .form-elem {
        margin: 20px;
        width: 50%;
    }
}