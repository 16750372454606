@import "./variables.scss";
.accountsList-container {
    position: relative;
    min-width: auto;
        height: 575px;
}
.table-header {
    // height: 40px !important;
    color: white;
    font-size: 15px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;




}

.MuiDataGrid-columnSeparator {
    visibility: 'hidden',
}

.grid-no-border {
    border: none !important
}