.form-input {
  width: calc(50% - 5px);
  margin-top: 10px !important;
  &.left {
    margin-right: 10px !important;
  }
  &.children {
    width: calc(25% - 13px);
    // margin-right: 10px !important;
  }
}

.form-button {
  position: absolute !important; // TODO: fix that ( not clean )
  bottom: 100px;
  right: 60px;
}

.borrower-container {
  text-align: start;
}

.borrower-title {
  text-align: center;
}
