@import "../variables.scss";
label>input[type="file"] {
    display: none;
}

.custom-file-upload {
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: $primary;
    font-weight: 500;
        font-size: 1rem;
        line-height: 1.75;
    display: inline-flex;
    padding: 12px 24px;
    cursor: pointer;
    width: 100%;
    color: white;
    justify-content: center;
        align-items: center;
}

.big-font-size {
    font-size: 5rem !important;
}
.upload-icon {
    margin-bottom: -2px;
        margin-right: 10px;
}