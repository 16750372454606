@import "../variables.scss";

h1,
h2,
h3 {
  color: $primary;
}
h1 {
  font-size: 1.4em;
  text-transform: uppercase;
}
h2 {
  font-size: 1.3em;
}
h3 {
  font-size: 1.1em;
}
.title {
  color: $primary;
}

.sub-title {
  color: $grey-text;
  padding-top: 10px;
  display: contents;
}

.text {
  padding-bottom: 10px;
  width: 100%;
  border-bottom: 1px solid;
  border-color: lighten($grey-text, 20%);
  margin-bottom: 10px;
}

.validate-button {
  text-align: center;
}

.validate-button > button {
  width: 80%;
  margin-top: 25px;
  margin-bottom: 25px;
}
