@import "../variables.scss";

.next-button {
  max-width: 300px;
  width: 100%;
  margin: auto !important;
  margin-top: 30px !important;
  &.action {
    font-weight: bold;
  }
}
