@import "../variables.scss";

.form-container {
  display: flex;
  flex-direction: row;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  width: 100% !important;
}

.right-side-title {
  color: $primary;

  @media (max-width: 500px) {
    margin-bottom: 30%;
  }
}

.right-side-content {
  margin-top: 5%;
  font-size: 62px !important;

  @media (max-width: 500px) {
    margin-left: 10%;
    margin-top: 5%;
    font-size: 34px;
    font-weight: 800;
    line-height: 87px;
    display: unset;
  }
}
.grecaptcha-badge {
  visibility: inherit;
}

.title-text {
  display: block;
  color: var(--neutral-900, #111827);
  font-feature-settings: "liga" off;
  font-family: "Roboto";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 50px; /* 156.25% */
  letter-spacing: -0.8px;
}
.title-and {
  color: var(--neutral-900, #111827);
  font-feature-settings: "liga" off;
  font-family: "Roboto Thin";
  font-size: 25px;
  font-style: normal;
  font-weight: 100;
  line-height: 50px;
  letter-spacing: -0.625px;
}
.title-subtext {
  display: block;
  color: var(--neutral-900, #111827);
  font-feature-settings: "liga" off;
  font-family: "Roboto Light";
  font-size: 32px;
  font-style: normal;
  font-weight: 300;
  line-height: 50px;
  letter-spacing: -0.8px;
}
