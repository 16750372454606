@import "../variables.scss";

.container_footer {
    height: 70px;
    //display: flex;
    //justify-content: center;
    margin-top: 50px;
}

.footer {
    border-top: solid 1px rgb(209, 209, 209);
    background-color: $white;
    display: flex;
    align-items: flex-start;
    color: white;
}

.footer-text {
    height: fit-content;
    color: black;
    padding-top: 7px;
    padding-left: 15px;
    font-size: x-small;
    font-weight: bold;
}

@media all and (min-width : 1px) and (max-width : 600px) {
    .footer {
        justify-content: center;
    }

    .footer-text {
        padding-left: unset;
    }
}