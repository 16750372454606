@import "../variables.scss";

.group-row {
  //   margin-left: 10px !important;
  border-bottom: 1px solid;
  border-color: lighten($grey-text, 15%);
  padding-bottom: 10px;
  .group-row-label {
    padding-left: 0;
  }
  &.disabled {
    color: #bbbbbb;
    background-color: #fafafa;
    .group-row-label {
      padding-left: 0;
    }
  }
}
.group-row-bank {
  border-bottom: 1px solid;
  border-color: lighten($grey-text, 15%);
  .group-row-label {
    padding-left: 0;
  }
}
.bank-list {
  margin-top: 10px;
}

.text-notice {
  margin-top: 10px;
  font-size: 0.9em;
  span {
    font-weight: bold;
  }
}
.title {
  color: $primary;
  font-weight: bold;
}

.sub-group {
  // margin: 25px 0 25px 15px;
  text-decoration: underline;
  color: $primary;
}

.group-row-icon {
  color: $primary;
}
.next-button {
  max-width: 300px;
  width: 100%;
  margin: auto !important;
  margin-top: 30px !important;
  &.action {
    font-weight: bold;
  }
}

.next-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
