.document-info {
  .main-title {
    font-family: "Roboto";
    font-size: 16px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: -0.025em;
    text-align: center;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  .info-group {
    border-left: 5px solid #305e8f;
    border-right: 5px solid #305e8f;
    padding: 20px 20px 30px 20px;
  }
  .subtitle {
    font-size: 18px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
    margin: 20px 0;
  }
  .content-info .label,
  .content-info .value {
    font-size: 14px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
  }
  .loading-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: -1px;
    margin-top: 20px;
  }
  .validation-text {
    font-size: 18px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0em;
  }
  @media (max-width: 900px) {
    .title-text,
    .title-and,
    .title-subtext {
      font-size: 20px;
      line-height: 30px;
    }
    .main-title {
      margin-top: 20px;
      margin-bottom: 0;
    }
    .subtitle {
      margin-top: 0;
      margin-bottom: 30px;
      font-size: 14px;
    }
  }
}
