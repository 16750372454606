@import "../variables.scss";

//Useful in mobile view
.link-navBar {
    text-decoration: none;
}

@media only screen and (min-width : 1px) and (max-width : 500px) {
    .username {
        display: none;
    }
}

.username-div {
    display: flex;
    align-content: center;
    border-left: 1px solid black;
    color: black;
    padding-left: 7px;
    margin-left: 2px;
    height: 40px;

    @media(max-width: 500px) {
        border-left: unset;
    }

}

.username {
    align-self: center;
}

.container_notifications_icon {
    border-right: 1px solid black;
}