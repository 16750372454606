@import "../variables.scss";

.doc-type-button {
  width: 18vw;
  cursor: default !important;
  margin: 5px !important;

  @media (min-width: 1200px) {
    width: 250px;
  }
}

.new-category {
  margin-top: 75px !important;
}

.document-type-list-column {
  display: "flex";

  flex-direction: "column";

  justify-content: "space-between";
}

.btn-validations {
  width: 220px;
}

.validation-icon {
  color: $primary;
}
.no-cursor > label {
  cursor: inherit !important;
}
