@import "../variables.scss";

.grid-container {
  position: relative;
  min-width: auto;
  height: "100%";
}

.table-header {
  // height: 40px !important;
  color: white;
  font-style: normal;
  font-size: 0.8125rem;
  font-weight: 700;
  line-height: 18px;
  text-align: center;
}

.MuiDataGrid-columnSeparator {
  visibility: "hidden";
}

.grid-no-border {
  border: none !important;
}
